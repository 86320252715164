// src/App.js
import React, { useEffect } from 'react';
import './App.css';
import FileUpload from './allFunctions';

function App() {
  useEffect(() => {
    document.title = "Meeting Note Taker"; // Change "Upload Video App" to your desired title
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Upload Video</h1>
        <FileUpload />
      </header>
    </div>
  );
}

export default App;

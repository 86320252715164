import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);
  const [notesCreated, setNotesCreated] = useState(false)
  const [watchingVideo, setWatchingVideo] = useState(false);
  const [takingNotes, setTakingNotes] = useState(false);
  const [userQuestion, setUserQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    // Update the state with the uploaded file's name
    setDownloadLink(null);
    setNotesCreated(false);
    setWatchingVideo(false);
    setTakingNotes(false);
    setAnswer('');
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('video', selectedFile);
      setWatchingVideo(true)
      try {
        console.log("here we go")
        const response = await axios.post('http://flask-env-working.eba-k6zpwbsb.us-east-2.elasticbeanstalk.com/convert_and_upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log(response.data);

        // You can use uploadedFileName for other functions later in your code.
        
        // After the file is successfully converted and uploaded, initiate transcription.
        initiateTranscription();
      } catch (error) {
        console.error("Error converting and uploading video:", error);
      } 
    } else {
      alert("Please select a video file before uploading.");
    }
  };

  // Function to initiate transcription of the uploaded audio file
  const initiateTranscription = async () => {
    const formData = new FormData();
    formData.append('audio_file', selectedFile);

    try {
      const response = await axios.post('http://flask-env-working.eba-k6zpwbsb.us-east-2.elasticbeanstalk.com/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);


      createNotes();

      // You can handle the response or provide user feedback as needed.
    } catch (error) {
      console.error("Error initiating transcription:", error);
    }
  };

  const createNotes = async () => {
    const formData = new FormData();
    const textFileName = selectedFile.name.replace(/\.[^.]+$/, '').replace(/[^a-zA-Z0-9]+/g, '') + '.txt';
    console.log(selectedFile.name)
    console.log(textFileName)
    formData.append('text_file_name', textFileName);
    setWatchingVideo(false);
    setTakingNotes(true);
    try {
      console.log("posting notes request")
      const response = await axios.post('http://flask-env-working.eba-k6zpwbsb.us-east-2.elasticbeanstalk.com/create_meeting_notes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setTakingNotes(false);
      setNotesCreated(true);
      // After creating notes, initiate a request to get the download link
      getDownloadLink();
    } catch (error) {
      console.error("Error creating notes:", error);
    }
  };

  const getDownloadLink = async () => {
    try {
      const formData = new FormData();
      const textFileName = selectedFile.name.replace(/\.[^.]+$/, '').replace(/[^a-zA-Z0-9]+/g, '') + '.txt';
      formData.append('text_file_name', textFileName);
      const response = await axios.post('http://flask-env-working.eba-k6zpwbsb.us-east-2.elasticbeanstalk.com/download_notes', formData);
      setDownloadLink(response.data.download_url);
    } catch (error) {
      console.error("Error getting download link:", error);
    }
  };

  const handleDownloadNotes = () => {
    if (downloadLink) {
      // Trigger the download by opening a new tab or window
      window.open(downloadLink);
    } else {
      alert("No download link available. Please create notes first.");
    }
  };

  const handleAnswerQuestion = async () => {
    const formData = new FormData();
    const textFileName = selectedFile.name.replace(/\.[^.]+$/, '').replace(/[^a-zA-Z0-9]+/g, '') + '.txt';
    formData.append('text_file_name', textFileName);
    if (userQuestion && textFileName) {
      try {
        formData.append('question', userQuestion);
        const response = await axios.post('http://flask-env-working.eba-k6zpwbsb.us-east-2.elasticbeanstalk.com/answer_question', formData);
        console.log(response.data)
        setAnswer(response.data.answer);  
      } catch (error) {
        console.error("Error answering the question:", error);
      }
    } else {
      alert("Please enter a question and create notes before asking.");
    }
  };

  return (
    
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload File and Create Notes</button>
      {watchingVideo && <p>Step 1: Watching Video...</p>}
      {takingNotes && <p>Step 2: Taking Notes...</p>}
      {notesCreated && <p>Notes Completed:</p> }
      {notesCreated && (
        <button
          style={{fontSize: '30px' }}
          onClick={handleDownloadNotes}
        >
          Download Notes
        </button>
      )}
    {notesCreated && (
        <div>
          <input
            type="text"
            placeholder="Ask a question"
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
          />
          <button onClick={handleAnswerQuestion}>Ask Question</button>
          {answer && <p>Answer: {answer}</p>}
        </div>
      )}
    </div>
  );
};

export default FileUpload;